import React, {useState, useEffect} from 'react'
import { motion } from 'framer-motion'
import './championships.scss'
import { urlFor, client } from "../../client";

const Championships = () => {
    const [activeFilter, setActiveFilter] = useState('All')
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1})
    const [championships, setChampionships] = useState([]);
    const [filterChampionship, setFilterChampionship] = useState([]);


    useEffect(() => {
      const query = '*[_type == "championships"]';
    
      client.fetch(query).then((data) => {
          setChampionships(data);
          setFilterChampionship(data);
      });
    }, []);
    
    const handleChampFilter = (item) => {
        setActiveFilter(item);
        setAnimateCard([{ y:100, opacity: 0 }]);
        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }])

            if (item === "All"){
                setFilterChampionship(championships);
            } else {
                setFilterChampionship(championships.filter((champ) => champ.years.includes(item)));
            }
        }, 500);
    };

  return (
    <div id='championships' className='app-champ'>
        <h1 className='champion-header-title'>Championships</h1>
        <div className='champ-filter'>
            {[ '2015', '2017', '2018', '2019', '2020', '2021', '2022','All'].map((item, index) => (
                <div
                    key={index}
                    onClick={() => handleChampFilter(item)}
                    className={`champ-filter-item app-flex p-text ${activeFilter === item ? 'item-active' : ''}`}
                >
                {item}
                </div>
            ))}
        </div>
        <motion.div
            animate={animateCard}
            transition={{ duration: 0.5, delayChildren: 0.5}}
            className="champ-cards"
        >
        {filterChampionship.map(( champ, index )=> (
            <div className='champ-card-item app-flex' key={index}>
                <div className='champ-card-img app-flex'>
                    <img src={urlFor(champ.imgUrl)}  alt={champ.name}/>
                </div>
                <div className='champ-card-content app-flex'>
                    <h4 >{champ.event}</h4>
                    <h3 >{champ.years[0]}</h3>
                    <p className='p-text ' style={{ marginTop: 10 }}>{champ.belt}</p>

                    <div className='champ-card-tag app-flex'>
                        <p className='p-text'>{champ.description}</p>
                    </div>
                </div>
            </div>
        ))}
        </motion.div>
    </div>
  )
}

export default Championships