import { React, useState } from 'react'
import './instructionals.scss';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import IconButton from '@mui/material/IconButton';
import { client } from '../../client';



const Instructionals = () => {
    const [isFormSubmitted, setisFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '' });
    const { username, email } = formData;




    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });

    };

    const handleSend = () => {
        setLoading(true);

        const contact = {
            _type: 'contact',
            name: username,
            email: email,
        }

        client.create(contact)
            .then(() => {
                setLoading(false);
                setisFormSubmitted(true);
            })
            .catch((err) => console.log(err));
    }




    return (


        <div id='instructionals' className='instructionals'>
            <h2 className='head-text'>
                Be the first to know when Instructionals release!
            </h2>
            <p className='subtitle-text-p'> The released content will have your very own Pedro Marinho teaching effective jiu jitsu techniques to use on the mats. 
                Pedro will also have guest professors and coaches who have aided him in his BJJ journey. <br/>The exclusive content will have everything from Jiu jitsu , Wrestling, Self Defense and 
                including Strength and Conditioning workouts to improve your fitness goals. 
            </p>
            {!isFormSubmitted ? (
                <div className='instruc-footer-form app-flex'>
                    <div className='app-flex'>
                        <input type='text' placeholder='Enter Your Name Here' name="username" value={username} onChange={handleChangeInput} required/>
                    </div>
                    <div className='app-flex'>
                        <input id='message'  type='email' placeholder='Enter Your Email Here' name="email" value={email} onChange={handleChangeInput} required />
                        
                    </div>

                    <button type='submit' className='p-text' onClick={handleSend}>{!loading ? 'Send' : 'Sending...'}</button>
                </div>

            ) : (
                <div>
                    <h3 className='complete-text'>Thank you for signing up! <br /> You will receive an email when the Instructionals release</h3>
                </div>
            )}

            <h3 className='subtitle-text'>Follow me on my Socials and stay up to date with my latest post!</h3>
                
            <div className='instruc-social-cards'>

                <div className='instruc-social-card'>
                    <IconButton href='https://www.instagram.com/pedromarinhojj' >
                        <InstagramIcon color='secondary' />
                    </IconButton>
                </div>
                <div className='instruc-social-card'>
                    <IconButton href='https://www.instagram.com/pedromarinhojj'>
                        <FacebookIcon color='primary' />
                    </IconButton>
                </div>

            </div>

            <div className='copyright app-flex'>
                <p >@2022 PedroMarinhoBJJ</p>
                <p >ALL RIGHTS RESERVED</p>
            </div>
        </div>
    )
}

export default Instructionals