import profile from '../assets/pedMaprofile.png';
import circle from '../assets/circle.svg';
import vb from '../assets/vb-logo.png';
import aboutImg from '../assets/aboutImg.JPG';
import fc from '../assets/fightersC-logo.png';
import ah from '../assets/alateHealth-logo.png';
import gb from '../assets/gb-logo.png'
import ab1 from '../assets/about-1.jpeg';
import ab2 from '../assets/about-2.jpeg';
import ab3 from '../assets/about-3.jpeg';
import ab4 from '../assets/about-4.jpeg';


const images = {
    profile, 
    circle, 
    vb,
    aboutImg,
    fc,
    ah,
    gb,
    ab1,
    ab2,
    ab3,
    ab4,
};

export default images;

// export default {
//     profile,
//     circle,
//     vb,
// }