import './navbar.scss'
import { React, useState } from 'react';
import { Close, LineWeight } from '@mui/icons-material';
import logo from '../../pmLogo.png'


const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false)

    return (
        <div className='navbar-wrapper'>
            <div className='navbar-links'>
                <div className='navbar-links-logo'>
                    <a href='/#'><img src={logo} alt='logo' /></a>
                </div>
                <div className='navbar-links-container'>
                    <p><a href='#about'>About</a></p>
                    <p><a href='#championships'>Championships</a></p>
                    {/* <p><a href='#merch'>Merch</a></p> */}
                    <p><a href='#instructionals'>Instructionals</a></p>
                </div>
            </div>
            <div className='navbar-register-signin'>
                <p><a href='#instructionals'>Sign In</a></p>
                <button type='button'><a href='#instructionals'>Register</a></button>
            </div>
            <div className='navbar-mobile-menu'>
                {toggleMenu ? <Close color="primary" fontSize="small" onClick={() => setToggleMenu(false)} />
                    : <LineWeight color="primary" fontSize="small" onClick={() => setToggleMenu(true)} />}
                {toggleMenu && (
                    <div className='navbar-mobile-menu__container scale-up-center'>
                        <div className='navbar-mobile-menu__container-links'>
                            <p><a href='#about'>About Pedro</a></p>
                            <p><a href='#championships'>Championships</a></p>
                            <p><a href='#merch'>Merch</a></p>
                            <p><a href='#instructionals'>Instructionals</a></p>
                        </div>
                        <div className='navbar-mobile-register-signin'>
                            <p><a href='#intructionals'>Sign In</a></p>
                            <button type='button'><a href='#instructionals'>Register</a></button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar