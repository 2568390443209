import React from 'react'
import { images } from '../../constants'
import './sponsors.scss'


const Sponsors = () => {
  return (
    <div className='sponsors-bar app-flex ' >
        <div>
            <img className='app-flex' src={images.vb} alt="brand-1" />
        </div>
        <div>
            <img className='app-flex' src={images.fc} alt="brand-2" />
        </div>
        <div>
            <img className='app-flex' src={images.ah} alt="brand-3" />
        </div>
        <div>
            <img className='app-flex' src={images.gb} alt="brand-4" />
        </div>
    </div>
  )
}

export default Sponsors