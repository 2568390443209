import React from 'react'
// import Navbar from './components/navbar/Navbar'
// import Header from './components/header/Header'
// import Sponsors from './components/sponsors/Sponsors'
// import About from './components/about/About'
// import Championships from './components/championships/Championships'
// import Instructionals from './components/instructionals/Instructionals'
import { Navbar, Header, Sponsors, About, Championships, Instructionals } from './components';
import './App.scss'
import Gallery from './components/gallery/Gallery';


const App = () => {
  return (
    <div className='App'>
    <div className='app-bg'>
        <Navbar />
        <Header />
        <Sponsors />
        <About />
        <Gallery />
        <Championships />
        <Instructionals />
    </div>
  </div>
  )
}

export default App
