import React from 'react'
import { images } from '../../constants'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


import './about.scss'



const About = () => {
    return (
        <div id='about' className='about-section section-padding '>
            <div className='about-info'>
                <h1 className='about-info-header'>ABOUT PEDRO</h1>
                <p>
                    <b>Pedro Marinho</b> was born on October 09, 1996, in Angra dos Reis, a famous municipality located in the state of Rio de Janeiro, Brazil.
                    An extremely active child while growing up, Marinho went through an array of sports while growing up. From more traditional activities such as soccer, skating, and surfing, to martial arts and combat sports.<br/>
                    A fascination for mixed martial arts MMA led Pedro to martial arts, with his first combat-sport experience being capoeira, a relationship that started when the Buzios native was only 7 years old.
                     From capoeira, Pedro went on to practice Thai boxing and Western boxing during his teenage years, finding jiu-jitsu shortly after his 17th birthday.
                    Master Reinaldo Fernandes of the Brazilian Top Team BTT started Marinho on his jiu-jitsu path, guiding the talented prospect for nearly 4 years.
                     In 2017 Pedro heard of a new project set by Felipe Pena named FP Golden Team.<br/><br/>
                      The idea behind FPGT was to select a few of the most talented emerging athletes in Brazil and give them a full scholarship at Pena’s training center in Belo Horizonte BH, Minas Gerais, Brazil.
                    The FP Golden Team selection process followed a series of steps, including a 2-day internship in BH where Marinho had to face other scholarship candidates.
                     In the end, out of over 1000 applicants, Pedro stood out and joined the program with the full support of Master Fernandes.<br/><br/>
                    Felipe Pena and the FP Team were also linked with the Gracie Barra team franchise, and it was through this GB network that Pedro Marinho met Ulpiano Malachias, one of the “Red Shield” team’s top instructors.
                     Ulpiano appreciated Marinho’s work and invited him to come and train at his gym in Houston, Texas, USA. Seeking more exposure for his booming career, Marinho joined Ulpiano in November 2018, later earning his brown belt from both Pena and Malachias.<br/><br/>
                    On March 13, 2021, Pedro Marinho was promoted to black belt by coach Ulpiano Malachias.
                </p>
            </div>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide><img src={images.aboutImg} alt="about1" /></SwiperSlide>
                <SwiperSlide><img src={images.ab1} alt="about2" /></SwiperSlide>
                <SwiperSlide><img src={images.ab2} alt="about3" /></SwiperSlide>
                <SwiperSlide><img src={images.ab3} alt="about4" /></SwiperSlide>
                <SwiperSlide><img src={images.ab4} alt="about5" /></SwiperSlide>

            </Swiper>
            {/* <div className='about-'>
                <img src={images.aboutImg} alt='pedro-marinho-ibjjf' />
            </div> */}

        </div>
    )
}

export default About