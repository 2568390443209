import React, {useState, useEffect} from 'react'
import { urlFor, client } from '../../client'
import './gallery.scss'


const Gallery = () => {
  const [gallery, setGallery] = useState([])


  useEffect(() => {
    const query = '*[_type == "gallery"]';
  
    client.fetch(query).then((data) => {
        setGallery(data);

    });
  }, []);


  return (
      <div className='gallery section-padding'>
          {gallery.map(( gall, index ) => (
            
              <div className='img-container' key={index}>
                <img src={urlFor(gall.imgUrl)} alt="gallery" key={index}/>
                </div>
          ))}
      </div>
  )
}

export default Gallery