import React from 'react'
import { motion } from 'framer-motion';
import { images } from '../../constants';
import './header.scss'




const Header = () => {
    return (
        <div id="home" className='app-header app__wrapper app-flex'>
            <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5, delayChildren: 0.5 }}
                className="app-header-img"
            >
                <img src={images.profile} alt="profile-bg" />
                <motion.img
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, ease: 'easeInOut' }}
                    src={images.circle}
                    alt="profile-circle"
                    className="overlay-circle"
                />
                {/* <motion.div
                    whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                    className="app-header-info"
                >
                    <div className='badge-cmp app-flex'>
                        <div>
                            <p className='p-tag'>Pedro Marinho</p>
                        </div>
                    </div>

                </motion.div> */}
                <div className='app-header-info'>
                    <div className='badge-cmp'>
                        <div className='stack'>
                            <span >PEDRO MARINHO</span>
                            <span >PEDRO MARINHO</span>
                            <span >PEDRO MARINHO</span>
                        </div>
                    </div>
                </div>
            </motion.div>

        </div>
    )
}

export default Header